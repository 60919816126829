.rich-text-container {
    h2, h3 {
        margin-top: 0.66em;
        margin-bottom: 0.3em;
    }

    ul, ol {
        color: #333;
        font-size: 1.6rem;
        font-weight: 300;
        letter-spacing: 0;
        padding: 1em 0 1em 2em;
        list-style-position: inside;
    }

    ul {
        list-style-type: disc;
    }

    p.subheading {
        font-size: 16px;
        font-weight: bold;
    }
}
