/* Header 12 */
.header-12 {
	.logo {
		margin-bottom: 2.5rem;
	}
	.header-dropdown-link {
		display: flex;
		align-items: flex-end;
	}
	.account,
	.wishlist,
	.compare-dropdown,
	.cart-dropdown {
	    display: flex;
	    align-self: stretch;
	    align-items: initial;

	    > a {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-around;
	    }
	}

	div.icon {
		position: relative;
	}
	.account a {
			font-size: 3rem;
			color: #333;
			line-height: 1;
	}

	.wishlist a {
		font-size: 2.8rem;
		color: #333;
		line-height: 1;
	}

	.cart-dropdown .cart-count,
	.wishlist .wishlist-count {
	    min-width: 1.6rem;
	    height: 1.6rem;
	}

	.wishlist .wishlist-count {
	    display: flex;
	    align-items: center;
	    justify-content: center;
	    border-radius: 50%;
	    font-weight: 400;
	    font-size: 1rem;
	    line-height: 1;
	    color: #fff;
	}

	.wishlist .wishlist-count {
		position: absolute;
		top: 0;
		right: -.5rem;
	}
	.cart-dropdown .cart-count {
		position: absolute;
		top: 0;
		right: -.3rem;
	}

	.cart-dropdown, .wishlist, .account {
		padding-left: 3rem;
		.dropdown-toggle {
			color: #333;
		}
	}

	.cart-dropdown,
	.wishlist,
	.account {
		align-items: flex-end;
		padding-left: 3.4rem;
		> a p {
			margin-top: .3rem;
			font-size: 1.1rem;
			font-weight: 300;
			letter-spacing: 0;
			transition: color .3s;
		}
		&:hover,
		&:focus,
		&.show {
			> a,
			> a p {
				color: #1cc0a0;
			}
		}
	}

	.wishlist {
		padding-left: 3rem;
	}
	.dropdown.category-dropdown {
		.dropdown-toggle {
			min-width: 264px;
			position: relative;
			color: #fff;
			background-color: #1cc0a0;
			padding-left: 5.6rem;
			padding-right: 3rem;

			&:after {
				margin-left: 0;
				position: absolute;
				left: 1.8rem;
				top: 50%;
				margin-top: -1.1rem;
			}

			&:before {
				display: block;
				height: auto;
				content: '';
				font-family: 'molla';
				content: '\f110';
				position: absolute;
				left: auto;
				right: 1.8rem;
				top: 50%;
				font-size: 1rem;
				line-height: 1;
				margin-top: -.5rem;
			}
		}

		&.show {
			.dropdown-toggle {
				&:before {
					content: '\e801';
				}
			}
		}
	}

	.header-dropdown > a,
	.header-dropdown > span {
		text-transform: none;
	}
	.top-menu {
		text-transform: none;
	}
	.header-menu ul {
		display: block;
	}
	.header-dropdown {
		padding-top: .7rem;
		padding-bottom: .7rem;
	}

	.header-top {
		font-size: 1.4rem;
		.header-left {
			letter-spacing: -.01em;
			i {
				margin-right: .8rem;
			}
		}
		.top-menu li + li {
			margin-left: 0;
		}
		.dropdown-links > li + li {
			margin-left: 4rem;
		}
	}
	.header-middle {
		border-bottom: none;
	}
	.header-bottom {
		.container {
			position: relative;
			&::after {
				content: '';
				position: absolute;
				top: 1.8rem;
				bottom: 1.8rem;
				width: .1rem;
				background-color: #ccc;
				right: 20.5%;
			}
		}

		.menu > li + li {
			margin-left: 2.8rem;
		}

		.menu > li {
			> a {
				padding: 1.55rem 3rem 1.55rem 1rem;

				&::before {
				content: '';
				    display: block;
				    position: absolute;
				    left: 0;
				    right: -.5rem;
				    width: unset;
				    bottom: 0;
				    height: .1rem;
				    background-color: #1cc0a0;
				    transform-origin: right center;
				    transform: scale(0, 1);
				    transition: transform .3s ease;
				}
			}

			&:hover,
			&:focus,
			&.active {
				> a {
					color: #1cc0a0;
					&::before {
					    transform-origin: left center;
    					transform: scale(1, 1);
					}
				}
			}
		}

		.header-right {
			margin-left: 2rem;
			i {
				font-size: 1.6rem;
				color: #fff;
			}
			p {
				font-size: 1.4rem;
				font-weight: 500;
				letter-spacing: -.01em;
				color: #fff;
				text-transform: none;
				padding-left: 1.5rem;
				margin-right: 4rem;
			}
		}
		.menu > li:not(:hover):not(.active):not(.show) > a {
			color: #fff;
		}
	}
	.sticky-header.fixed {
		background-color: #333;
	}
	.header-search-extended {
		#cat,
		.form-control {
			background-color: #f8f8f8;
		}

		.form-control {
			padding-left: 2rem;
		}

		.select-custom {
			flex: 0 0 213px;
			max-width: 213px;
		}
	}
}


@include mq('xxl') {

	.header-12 {
		.header-search-extended {
			margin-right: 7rem;
		}
		
		.header-search .search-wrapper-wide {
			width: 832px;
		}

		.header-search-extended {
			max-width: 832px;
		}
	}
}

@include mq('lg', 'max') {
	.header-12 {
		.header-search-visible {
			.header-search-wrapper {
				&:before {
					border-bottom-color: #dadada;
				}
			}
		}
	}
}


@include mq('xl') {
	.header-12 {
		.header-text {
			display: block;
		}
	}
}

@media screen and (max-width: 1279px) {
	.header-12 .header-bottom {
		.menu > li + li {
			margin-left: .5rem;
		}
		.container::after {
			visibility: hidden;
		}
	}
}

@media screen and (max-width: 1140px) {
	.header-12 .header-bottom {
		.header-right {
			margin-left: 0;
			p {
				font-size: 1.3rem;
				margin-right: 1rem;
			}
		}
		.menu > li > a {
			font-size: 1.3rem;
		}
	}
	.header-12 .dropdown.category-dropdown .dropdown-toggle {
		font-size: 1.3rem;
		min-width: 150px;
		padding-left: 5rem;
		&::before {
			right: 1rem;
		}
	}
}

@media screen and (max-width: 395px) {
	.header-12 .wishlist,
	.header-12 .account {
		display: none;
	}
}