/* Counters */
.count-container {
	margin-bottom: 3.3rem;
}

.count-wrapper {
	color: $primary-color;
	font-weight: 400;
	font-size: 3rem;
	line-height: 1.2;
	margin-bottom: .6rem;
}

.count-title {
	font-weight: 400;
	font-size: 1.6rem;
	letter-spacing: -.01em;
}

@include mq('lg') {
}