.affiliate-company-cards-container {
    position: relative;
    margin: 20px auto;
}

.affiliate-company-details-text {
    font-weight: bold;
    position: absolute;
    top: 10px;
    left: 30px;
    line-height: normal;
}

.affiliate-company-cards {
    padding-top: 30px;
    justify-content: center;
    background-color: #fff;
    border: 1px solid #e6e6e6;
    padding: 40px 10px 40px;
}

@media (min-width: 992px) {
    .affiliate-company-cards {
        display: flex;
        align-items: flex-end;
        padding-top: 20px;
        height: 190px;
    }
}

.affiliate-company-card {
    padding: 15px;
    border: 1px solid #e7e7e7;
    box-shadow: 2px 2px 2px #ddd;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .affiliate-card-title {
        font-weight: bold;
    }

    .affiliate-card-image {
        width: 120px;
        overflow: hidden;
        margin-right: 10px;
    }

    .affiliate-card-rating {
        /*        position: absolute;
        top: 10px;
        right: 20px;*/
        color: royalblue;
        font-weight: bold;

        div {
            display: inline-block;
        }

        .affiliate-card-rating-box {
            background-color: royalblue;
            border-radius: 2px;
            width: 40px;
            text-align: center;
            color: #fff;
            margin-left: 0.6em;
        }
    }

    .affiliate-card-link {
        font-size: 1.6rem !important;

        a {
            color: orangered;
        }
    }
}

.affiliate-card-top {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.affiliate-card-bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    p {
        font-size: 1.2rem;
    }
}

.affiliate-company-card-1,
.affiliate-company-card-2,
.affiliate-company-card-3 {
    position: relative;
}

.affiliate-company-card-1 {
    order: 2;

    .affiliate-company-card {
        height: 120px;
    }
}

.affiliate-company-card-2 {
    order: 1;

    .affiliate-company-card {
        height: 100px;
    }
}

.affiliate-company-card-3 {
    order: 3;

    .affiliate-company-card {
        height: 100px;
    }
}